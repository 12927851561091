import { useQueryClient } from "react-query";
import { v4 as uuidv4 } from "uuid";

import { config } from "../components/config";

export function useAdvertiserSearch() {
  const queryClient = useQueryClient();
  return async function ({ tenantId, token, marketId, advertiserName }) {
    async function advertiserSearch({ queryKey }) {
      const [, tenantId, token, marketId, advertiserName] = queryKey;
      const response = await fetch(
        `${config.server}/advertisers/search/${marketId}`,
        {
          headers: {
            Authorization: `Bearer ${tenantId}::${token}`,
            Accept: "application/json",
            "x-request-id": uuidv4(),
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            advertiserName,
            marketId,
            isDirect: true,
          }),
        }
      );
      return await response.json();
    }

    return await queryClient.fetchQuery(
      ["advertiserSearch", tenantId, token, marketId, advertiserName],
      advertiserSearch
    );
  };
}
