import { IncludeBootstrap } from "./IncludeBootstrap";
import { IncludeSyncfusionCss } from "./IncludeSyncfusionCss";

export function IncludeCss({ children, localApp }) {
  return (
    <IncludeBootstrap>
      <IncludeSyncfusionCss>{children}</IncludeSyncfusionCss>
    </IncludeBootstrap>
  );
}
