import { Header } from "./header";

export function OrderComponent(props) {
  return (
    <div className="row col-12 m-0">
      <div
        className="content col-12 p-3 align-middle smallcontainer"
        style={{ paddingLeft: "280px!important", height: "90vh" }}
      >
        <Header {...props} />
      </div>
    </div>
  );
}
