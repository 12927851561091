import { useRef } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
} from "@syncfusion/ej2-react-grids";

export function AdvertiserSearchResults({
  advertisers,
  setAdvertiser,
  setFieldValue,
}) {
  const gridRef = useRef(null);

  function rowSelected() {
    if (gridRef.current) {
      const selected = gridRef.current.getSelectedRecords();
      if (selected) {
        setFieldValue("advertiserId", selected[0].id);
        setAdvertiser(selected[0]);
      }
    }
  }

  return (
    <div>
      <GridComponent
        dataSource={advertisers}
        ref={gridRef}
        rowSelected={() => rowSelected()}
      >
        <ColumnsDirective>
          <ColumnDirective field="name" headerText="Advertiser Name" />
        </ColumnsDirective>
      </GridComponent>
    </div>
  );
}
