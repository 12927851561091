import { useState } from "react";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";

import styles from "./NewOrderComponent.module.css";
import { NewOrderForm } from "./NewOrderForm";

export function NewOrderComponent({
  markets,
  advertiserSearch,
  setAdvertiser,
  setMarketId,
  onSubmit,
  navigate,
}) {
  const [submit, setSubmit] = useState(false);
  const [disabled, setDisabled] = useState(true);

  function dialogClose() {
    navigate("/");
  }

  function header() {
    return (
      <div className={styles.headerContent}>
        Provide a title and a customer for this order
      </div>
    );
  }

  function footer() {
    return (
      <div className={styles.footerContent}>
        <ButtonComponent
          cssClass="e-cancelbutton text-capitalize ml-2 mr-2"
          onClick={() => {
            navigate("/");
          }}
        >
          Cancel
        </ButtonComponent>
        <ButtonComponent
          disabled={disabled}
          cssClass="pl-1 pr-1 e-okbutton text-capitalize ml-2 mr-2"
          onClick={() => {
            setSubmit(true);
          }}
        >
          Assign Customer
        </ButtonComponent>
      </div>
    );
  }

  return (
    <div id="dialog-target">
      <DialogComponent
        header={header}
        footerTemplate={footer}
        target="#dialog-target"
        close={dialogClose}
        showCloseIcon={true}
        closeOnEscape={false}
        visible={true}
        style={{
          maxHeight: "1143px",
          zIndex: 1002,
          width: "50%",
          marginTop: "200px",
          maxWidth: "60%",
          marginLeft: "25%",
        }}
      >
        <div className={styles.dialogContent}>
          <NewOrderForm
            markets={markets}
            advertiserSearch={advertiserSearch}
            submit={submit}
            setSubmit={setSubmit}
            disabled={disabled}
            setDisabled={setDisabled}
            setAdvertiser={setAdvertiser}
            setMarketId={setMarketId}
            onSubmit={onSubmit}
          />
        </div>
      </DialogComponent>
    </div>
  );
}
