import { DateTime } from "luxon";

export const orderSkeleton = (
  name,
  marketId,
  advertiserId,
  advertiserAccountId,
  makeGoodPolicyId,
  competitiveTypeId,
  revenueTypeId,
  salespersonId
) => ({
  state: "new",
  name,
  advertiser_id: advertiserId,
  global_revenue_class_id: 0,
  billing_cycle_id: null,
  make_good_policy_id: makeGoodPolicyId,
  competitive_type_id: competitiveTypeId,
  revenue_type_id: revenueTypeId,
  traffic_campaign_markets: [
    {
      market_id: marketId,
      account_id: advertiserAccountId,
      campaign_market_sales_reps: [
        {
          salesperson_id: salespersonId,
          airtime_commission: "1.0",
          is_primary: true,
        },
      ],
      detail: {
        agency_commission: null,
        product_name: null,
        product_code: null,
        estimate_number: null,
        client_code: null,
        print_options: 0,
        affidavit_only: false,
        invoice_per_product: false,
        coop_script: false,
        confirmation_printable: true,
        airtime_commission: "0.0",
        airtime_commission_outlet_id: null,
        order_discount: "0.0",
      },
    },
  ],
  _bypass_warnings: true,
});

//TODO: This logic is not the full logic and is greatly simplified.
//Update this to take care of all cases. See: https://github.com/Marketron/react-components/blob/master/src/lib/components/commons/advertiserSearch/AdvertiserAccountSelect.js#L22
export function getAdvertiserAccountId(advertiser, marketId) {
  const account = advertiser.accounts.find((account) =>
    account.account_markets.find((am) => am.active && am.market_id === marketId)
  );
  const advertiserAccount = account.account_markets.find(
    (am) => am.active && am.market_id === marketId
  );
  return advertiserAccount.id;
}

export function getMarketDefaults(markets, marketId) {
  const market = markets.find((market) => market.id === marketId);
  const { makegood_policy_id, competitive_type_id, direct_revenue_type_id } =
    market.market_default;
  return {
    makeGoodPolicyId: makegood_policy_id,
    competitiveTypeId: competitive_type_id,
    revenueTypeId: direct_revenue_type_id,
  };
}

export function orderName(advertiser) {
  const dt = DateTime.now();
  return `${advertiser.name} ${dt.toFormat("ddLLyyyy HHmm")}`;
}

export function getDefaultSalespersonId(user, orderLookups) {
  const { uid } = user;
  const salespersonById = orderLookups.salespersons.find(
    (salesperson) => salesperson.active && salesperson.user_id === uid
  );
  const salesperson = salespersonById
    ? salespersonById
    : orderLookups.salespersons[0];
  return salesperson.id;
}
