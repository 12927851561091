import { useQueryClient } from "react-query";
import { v4 as uuidv4 } from "uuid";

import { config } from "../components/config";

export function useGetMarkets() {
  const queryClient = useQueryClient();
  return async function ({ tenantId, token }) {
    async function getMarkets({ queryKey }) {
      const [, tenantId, token] = queryKey;
      const response = await fetch(`${config.server}/markets`, {
        headers: {
          Authorization: `Bearer ${tenantId}::${token}`,
          Accept: "application/json",
          "x-request-id": uuidv4(),
        },
        method: "GET",
      });
      return await response.json();
    }
    const query = await queryClient.fetchQuery(
      ["getMarkets", tenantId, token],
      getMarkets
    );
    return query.result;
  };
}
