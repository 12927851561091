import { useState } from "react";

function getServer(serverName) {
  switch (serverName) {
    case "dev":
      return "https://0gzwv618g4.execute-api.us-east-1.amazonaws.com/dev/api/v1.0/auth/login";
    case "qa":
      return "https://qf9pbimq0c.execute-api.us-east-1.amazonaws.com/qa/api/v1.0/auth/login";
    case "qa1":
      return "https://u9k99h0hz4.execute-api.us-east-1.amazonaws.com/qa1/api/v1.0/auth/login";
    case "staging":
      return "https://xdub3z17n6.execute-api.us-east-1.amazonaws.com/staging/api/v1.0/auth/login";
    case "localhost":
      return "http://localhost:30303/auth/login";
    default:
      return "";
  }
}

export function GetToken({ setTenantId, setToken }) {
  const [serverName, setServerName] = useState(
    process.env.REACT_APP_SERVER_NAME
  );
  const [server, setServer] = useState(
    process.env.REACT_APP_SERVER ||
      getServer(process.env.REACT_APP_SERVER_NAME) ||
      getServer("dev")
  );
  const [username, setUsername] = useState(process.env.REACT_APP_USERNAME);
  const [tenant, setTenant] = useState(process.env.REACT_APP_TENANT);

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        var myHeaders = new Headers();
        myHeaders.append(
          "X-Amz-Target",
          "AWSCognitoIdentityProviderService.InitiateAuth"
        );
        myHeaders.append("Content-Type", "application/x-amz-json-1.1");

        var body = {
          AuthParameters: {
            USERNAME: "chananb+test1@gmail.com",
            PASSWORD: "job4DREE-jand7flac",
          },
          AuthFlow: "USER_PASSWORD_AUTH",
          ClientId: "3or47eutovsjatrqt4u7tk1rqg",
        };

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(body),
        };

        var response = await fetch(
          "https://cognito-idp.us-east-1.amazonaws.com/",
          requestOptions
        );
        var json = await response.json();

        myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          `Bearer ${json.AuthenticationResult.IdToken}`
        );

        body = { tenant_id: parseInt(tenant), userName: username };

        requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(body),
        };

        response = await fetch(server, requestOptions);
        json = await response.json();
        setTenantId(tenant);
        setToken(json.result.accessToken);
      }}
    >
      <h3>Get Token Form</h3>
      <p>
        You can use this form to auto generate a token for the token login form
        below. You can store your preferred values in a <code>.env</code> file
        so you dont have to paste them in every time. The values are:
      </p>
      <ul>
        <li>REACT_APP_SERVER_NAME</li>
        <li>
          REACT_APP_SERVER (Optional, only if you want to override server url
          for localhost use)
        </li>
        <li>REACT_APP_USERNAME</li>
        <li>REACT_APP_TENANT</li>
      </ul>
      <p>
        You can override the server url in the form below with a custom value if
        you run radigio locally.
      </p>
      <h3>Local url</h3>
      <p>
        You might want to be running on:{" "}
        <a href="http://test.clients.lvh.me:3000">
          http://test.clients.lvh.me:3000
        </a>
      </p>
      <div>
        <label>
          Server name:{" "}
          <select
            value={serverName}
            onChange={(e) => {
              setServer(getServer(e.target.value));
              setServerName(e.target.value);
            }}
          >
            <option>dev</option>
            <option>qa</option>
            <option>qa1</option>
            <option>staging</option>
            <option>localhost</option>
          </select>
        </label>
      </div>
      <div>
        <label>
          Server:{" "}
          <input
            type="text"
            value={server}
            size="75"
            onChange={(e) => setServer(e.target.value)}
          />
        </label>
      </div>
      <div>
        <label>
          Username:{" "}
          <input
            type="text"
            value={username}
            size="75"
            onChange={(e) => setUsername(e.target.value)}
          />
        </label>
      </div>
      <div>
        <label>
          Password: <input type="password" size="75" />
        </label>
      </div>
      <div>
        <label>
          Tenant Id:{" "}
          <input
            type="text"
            value={tenant}
            onChange={(e) => setTenant(e.target.value)}
          />
        </label>
      </div>
      <input type="submit" value="Login" />
    </form>
  );
}
