import { ButtonComponent } from "@syncfusion/ej2-react-buttons";

import { useFrameContext } from "../../frameContext";

export function Advertiser({ order, visible, setVisible }) {
  const { navigate } = useFrameContext();
  return (
    <div
      className="col-5 bg-transparent p-0"
      style={{
        border: "0px",
        whiteSpace: "nowrap",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <div className="row" style={{ alignItems: "center" }}>
        <div style={{ paddingLeft: "20px", height: "25px", width: "100%" }}>
          <ButtonComponent
            cssClass="btn e-primary shadow-none back-button"
            iconCss="e-icons Chevron-back"
            onClick={() => navigate("/")}
          >
            Back
          </ButtonComponent>
        </div>
        <ButtonComponent
          cssClass="bg-transparent shadow-none pl-3 pb-2"
          iconCss={`e-icons ${visible ? "Chevron-open" : "Chevron-closed"}`}
          onClick={() => setVisible(!visible)}
        />
        <span
          className="pr-0 auto-resize-title-font"
          style={{ fontSize: "20px", color: "#585858", fontWeight: "normal" }}
        >
          {order.advertiser.name}
        </span>
        <ButtonComponent
          cssClass="bg-transparent shadow-none mb-2 pr-3"
          iconCss="e-icons EditItem"
          onClick={() => console.log("clicked")}
        />
        <div
          className="dot OrderStatus-lightblue"
          data-toggle="tooltip"
          data-placement="bottom"
          title={`  Review Required - ${order.id} `}
        ></div>
        <span
          className="pl-1 auto-resize-title-font"
          style={{
            fontSize: "18px",
            color: "#797979",
            fontWeight: "normal",
            paddingLeft: "20px",
            marginTop: "1px",
          }}
          data-toggle="tooltip"
          data-placement="bottom"
          title={`  Review Required - ${order.id} `}
        >
          {order.id}
        </span>
      </div>
    </div>
  );
}
