import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";

import { OrderReview } from "./OrderReview";
import { OrderSelections } from "./OrderSelections";
import { SpotPlacements } from "./SpotPlacements";
import { NonSpotItems } from "./NonSpotItems";
import { ThirdPartyDigital } from "./ThirdPartyDigital";
import { OrderDetails } from "./OrderDetails";
import { Attachments } from "./Attachments";
import { Notes } from "./Notes";
import { DigitalPlacement } from "./DigitalPlacement";

export function Tabs({ order, tenant, token }) {
  return (
    <div>
      <TabComponent>
        <TabItemsDirective>
          <TabItemDirective
            header={{ text: "Order Review" }}
            content={() => (
              <OrderReview order={order} tenant={tenant} token={token} />
            )}
          />
          <TabItemDirective
            header={{ text: "Order Selections" }}
            content={() => (
              <OrderSelections order={order} tenant={tenant} token={token} />
            )}
          />
          <TabItemDirective
            header={{ text: "Spot Placements" }}
            content={() => (
              <SpotPlacements order={order} tenant={tenant} token={token} />
            )}
          />
          <TabItemDirective
            header={{ text: "Non-Spot Items" }}
            content={() => (
              <NonSpotItems order={order} tenant={tenant} token={token} />
            )}
          />
          <TabItemDirective
            header={{ text: "3rd Party Digital" }}
            content={() => (
              <ThirdPartyDigital order={order} tenant={tenant} token={token} />
            )}
          />
          <TabItemDirective
            header={{ text: "Digital Placements" }}
            content={() => (
              <DigitalPlacement order={order} tenant={tenant} token={token} />
            )}
          />
          <TabItemDirective
            header={{ text: "Order Details" }}
            content={() => (
              <OrderDetails order={order} tenant={tenant} token={token} />
            )}
          />
          <TabItemDirective
            header={{ text: "Attachments" }}
            content={() => (
              <Attachments order={order} tenant={tenant} token={token} />
            )}
          />
          <TabItemDirective
            header={{ text: "Notes" }}
            content={() => (
              <Notes order={order} tenant={tenant} token={token} />
            )}
          />
        </TabItemsDirective>
      </TabComponent>
    </div>
  );
}
