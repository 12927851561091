import { useState } from "react";

import { HeaderDetails } from "./HeaderDetails";
import { OrderSummary } from "./OrderSummary";
import { Advertiser } from "./Advertiser";
import { HeaderActionButtons } from "./HeaderActionButton";
import { Tabs } from "../tabs";
import "./styles.css";

export function Header({
  order,
  orderLookups,
  tenant,
  tenantId,
  token,
  updateOrder,
}) {
  const [visible, setVisible] = useState(false);
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "-webkit-sticky",
          paddingTop: "5px",
          top: "57px !important",
          zIndex: 100,
          backgroundColor: "#f9f9f9",
        }}
      >
        <div
          className="col-12"
          style={{
            paddingLeft: "20px",
            paddingRight: "0px",
            paddingBottom: "7px",
          }}
        >
          <div className="row" style={{ height: "81px" }}>
            <Advertiser
              order={order}
              visible={visible}
              setVisible={setVisible}
            />
            <OrderSummary order={order} />
            <HeaderActionButtons order={order} />
          </div>
        </div>
      </div>
      <HeaderDetails
        order={order}
        orderLookups={orderLookups}
        visible={visible}
        updateOrder={updateOrder}
      />
      <Tabs order={order} tenant={tenant} tenantId={tenantId} token={token} />
    </div>
  );
}
