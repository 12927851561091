export const config = {
  revHost: "rev-dev.mktnrev.com",
};

if (window.location.hostname.indexOf("acme.mc3.local") !== -1) {
  config.server = "http://localhost:3030";
}
if (window.location.hostname.indexOf("clients.lvh.me") !== -1) {
  config.server =
    "https://0gzwv618g4.execute-api.us-east-1.amazonaws.com/dev/api/v1.0";
}
if (window.location.hostname.indexOf("localhost") !== -1) {
  config.server =
    "https://0gzwv618g4.execute-api.us-east-1.amazonaws.com/dev/api/v1.0";
}
if (window.location.hostname.indexOf("sales-cloud-dev.mktnxt.com") !== -1) {
  config.server =
    "https://0gzwv618g4.execute-api.us-east-1.amazonaws.com/dev/api/v1.0";
}
