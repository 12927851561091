import { useRef } from "react";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import _ from "lodash";

export function SalesRep({ order, orderLookups, updateOrder }) {
  const ref = useRef();
  const salespersons = orderLookups?.salespersons
    .filter((salesperson) => salesperson.active)
    .map((salesperson) => ({
      text: `${salesperson.first_name} ${salesperson.last_name}`,
      value: salesperson.id,
    }));
  const salesRepId =
    order.traffic_campaign_markets[0].campaign_market_sales_reps[0]
      .salesperson_id;
  const salesRep = salespersons.find(
    (salesperson) => salesperson.value === salesRepId
  ).text;

  return (
    <>
      <td
        className="customerCardHeader text-nowrap customerresize-element"
        style={{ whiteSpace: "nowrap" }}
      >
        Sales Rep
      </td>
      <td
        className="customerdetails p-0 text-nowrap customerresize-element p-0 m-0"
        style={{ whiteSpace: "nowrap" }}
      >
        <DropDownListComponent
          data-cy="sales-rep"
          cssClass="customerdetails-dropdown customerresize-element m-0"
          dataSource={salespersons}
          placeholder="Select Sales Rep"
          value={salesRepId}
          text={salesRep}
          onChange={async () => {
            const updatedOrder = _.cloneDeep(order);
            updatedOrder.traffic_campaign_markets[0].campaign_market_sales_reps[0].salesperson_id =
              ref.current.itemData.value;
            await updateOrder(updatedOrder);
          }}
          ref={ref}
        />
      </td>
    </>
  );
}
