import { useEffect } from "react";

export function SetDisabled({ isValid, disabled, setDisabled }) {
  useEffect(() => {
    if (isValid !== !disabled) {
      setDisabled(!isValid);
    }
  }, [isValid, disabled, setDisabled]);
  return null;
}
