import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";

export function OrderSummary({ order }) {
  return (
    <div
      className="col-5 bg-transparent p-0 "
      style={{
        border: "0px",
        whiteSpace: "nowrap",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <div className="col-3 p-0">
        <div className="row pt-0 ml-0 mr-0 flex-nowrap bg-transparent border-right border-left">
          <NumericTextBoxComponent
            value={0}
            cssClass="aligntotal"
            readonly="true"
            format="n0"
            floatLabelType="Auto"
            showSpinButton={false}
            placeholder="REACH"
          />
          <ButtonComponent
            cssClass="e-flat m-2 mr-4"
            iconCss="e-icons e-rundiscount"
            onClick={() => console.log("clicked")}
          />
        </div>
      </div>
      <div className="col-2 p-0">
        <div className="row pt-0 ml-0 mr-0 flex-nowrap bg-transparent border-right">
          <NumericTextBoxComponent
            value={0}
            cssClass="aligntotal"
            readonly="true"
            format="n0"
            floatLabelType="Auto"
            showSpinButton={false}
            placeholder="Bonus"
          />
        </div>
      </div>
      <div className="col-2 p-0">
        <div className="row pt-0 ml-0 mr-0 flex-nowrap bg-transparent border-right">
          <NumericTextBoxComponent
            value={0}
            cssClass="aligntotal"
            readonly="true"
            format="n0"
            floatLabelType="Auto"
            showSpinButton={false}
            placeholder="Spots"
          />
        </div>
      </div>
      <div className="col-3 p-0">
        <div className="row pt-0 ml-0 mr-2 flex-nowrap bg-transparent border-right">
          <NumericTextBoxComponent
            value={0}
            cssClass="aligntotal"
            readonly="true"
            format="c2"
            floatLabelType="Auto"
            showSpinButton={false}
            placeholder="Total"
          />
        </div>
      </div>
      <div className="col-2 p-0">
        <div className="row pt-0 ml-0 mr-2 flex-nowrap bg-transparent">
          <NumericTextBoxComponent
            value={0}
            cssClass="aligntotal"
            readonly="true"
            format="p2"
            floatLabelType="Auto"
            showSpinButton={false}
            placeholder="Discount"
          />
        </div>
      </div>
    </div>
  );
}
