import { Order } from "./order";
import { IFrame as IFrameComponent } from "./iframe";
import { NewOrder as NewOrderComponent } from "./newOrder";
import { Wrapper } from "./Wrapper";

export { useAuthenticate } from "./auth";

export function SalesCloudOrder(props) {
  return (
    <Wrapper {...props}>
      <Order {...props} />
    </Wrapper>
  );
}

export function IFrame(props) {
  return (
    <Wrapper {...props}>
      <IFrameComponent {...props} />
    </Wrapper>
  );
}

export function NewOrder(props) {
  return (
    <Wrapper {...props}>
      <NewOrderComponent {...props} />
    </Wrapper>
  );
}
