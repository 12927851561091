import { SalesRep } from "./SalesRep";
import { OrderName } from "./OrderName";

export function HeaderDetails({ order, orderLookups, visible, updateOrder }) {
  if (!visible) {
    return null;
  }
  return (
    <div className="col-12 p-0 mb-3">
      <div className="col-12 p-3 control-section bg-white">
        <OrderName order={order} updateOrder={updateOrder} />
        <div className="col-12  m-0 d-flex align-items-end flex-column p-0">
          <div
            className="e-input-group border-0 p-0"
            style={{ paddingLeft: "4px!important" }}
          >
            <div
              className="row col-12 pt-3 pb-2 pl-0"
              style={{ lineHeight: "2.5" }}
            >
              <div
                className="col-3 m-0 "
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "0px",
                  paddingLeft: "14px",
                }}
              >
                <span
                  className="subheading-title-font customerCardHeader text-nowrap text-capitalize"
                  style={{ color: "#a0a0a0!important" }}
                >
                  {order.dates}
                </span>
              </div>
              <div
                className="col-3 m-0"
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "0px",
                  paddingLeft: "18px",
                }}
              >
                <span
                  className="subheading-title-font customerCardHeader text-capitalize text-nowrap"
                  style={{ color: "#a0a0a0!important" }}
                >
                  Purchase Order
                </span>
                <span className="display-POicon m-0 subheading-title-font po-textbox po-padding">
                  <input
                    className="e-control e-textbox e-lib e-input"
                    type="text"
                    placeholder="Enter Purchase Order"
                    aria-placeholder="Enter Purchase Order"
                    autoComplete="on"
                    aria-disabled="false"
                    spellCheck="false"
                    data-ms-editor="true"
                  />
                </span>
                <i className="e-icons EditItem display-iconCustomerPO"></i>
              </div>
              <div
                className="col-3 m-0 "
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "0px",
                  paddingLeft: "24px",
                }}
              >
                <span
                  className="subheading-title-font customerCardHeader text-capitalize text-nowrap"
                  style={{ color: "#a0a0a0!important" }}
                >
                  Credit Status
                </span>
                <span
                  className="subheading-title-font customerCardHeader text-nowrap"
                  style={{
                    color: "#585858!important",
                    paddingLeft: "15px",
                    backgroundColor: "white",
                    borderRadius: "15px",
                    width: "150px",
                  }}
                >
                  ???
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-0  mb-2 p-0 customertable">
          <table className="detailtable bg-white" width="100%">
            <colgroup>
              <col width="10%" />
              <col width="15%" />
              <col width="10%" />
              <col width="15%" />
              <col width="10%" />
              <col width="15%" />
              <col width="10%" />
              <col width="15%" />
            </colgroup>
            <tbody>
              <tr>
                <td
                  className="customerCardHeader text-nowrap customerresize-element"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Contact
                </td>
                <td
                  className="customerdetails p-0 text-nowrap customerresize-element m-0"
                  style={{ whiteSpace: "nowrap" }}
                >
                  ???
                </td>

                <td
                  className="customerCardHeader text-nowrap customerresize-element"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Agency
                </td>
                <td
                  className="customerdetails p-0 text-nowrap customerresize-element m-0"
                  style={{ whiteSpace: "nowrap" }}
                >
                  ???
                </td>
                <SalesRep
                  order={order}
                  orderLookups={orderLookups}
                  updateOrder={updateOrder}
                />

                <td
                  className="customerCardHeader text-nowrap customerresize-element"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Product
                </td>
                <td
                  className="customerdetails p-0 text-nowrap customerresize-element  m-0"
                  style={{ whiteSpace: "nowrap" }}
                >
                  ???
                </td>
              </tr>
              <tr></tr>
              <tr></tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
