import { useQueryClient } from "react-query";
import { v4 as uuidv4 } from "uuid";

import { config } from "../components/config";

export function useGetOrderLookups() {
  const queryClient = useQueryClient();
  return async function ({ tenantId, token, marketId }) {
    async function getOrderLookups({ queryKey }) {
      const [, tenantId, token, marketId] = queryKey;
      if (!marketId) {
        return undefined;
      }
      const response = await fetch(
        `${config.server}/orders/lookups/${marketId}`,
        {
          headers: {
            Authorization: `Bearer ${tenantId}::${token}`,
            Accept: "application/json",
            "x-request-id": uuidv4(),
          },
          method: "GET",
        }
      );
      return await response.json();
    }
    const query = await queryClient.fetchQuery(
      ["order-lookups", tenantId, token, marketId],
      getOrderLookups
    );
    return query?.result;
  };
}
