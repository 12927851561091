import { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  useGetMarkets,
  useGetOrder,
  useGetOrderLookups,
  useAdvertiserSearch,
  usePostOrder,
} from "../hooks";

const types = {
  INIT: "INIT",
  NAVIGATE: "NAVIGATE",
  GET_ORDER: "GET_ORDER",
  GET_ORDER_LOOKUPS: "GET_ORDER_LOOKUPS",
  GET_MARKETS: "GET_MARKETS",
  ADVERTISER_SEARCH: "ADVERTISER_SEARCH",
  POST_ORDER: "POST_ORDER",
};

export function MspIframe({ tenant, tenantId, token, uri }) {
  const frameRef = useRef(null);
  const [origin, setOrigin] = useState();
  const messages = useRef([]);
  const navigate = useNavigate();
  const getOrder = useGetOrder();
  const getOrderLookups = useGetOrderLookups();
  const getMarkets = useGetMarkets();
  const advertiserSearch = useAdvertiserSearch();
  const postOrder = usePostOrder();
  const { orderId } = useParams();
  const tempUri = uri.replace(":orderId", orderId);
  const src = `${window.location.protocol}//${window.location.host}${
    window.location.port === "" ? ":" + window.location.port : ""
  }${tempUri}?tenant=${tenant}&tenantId=${tenantId}&token=${token}`;

  useEffect(() => {
    //Turned off logging from the parent
    //If needed uncomment the line below and the line in postMessage function
    function log(message) {
      if (message.data.type) {
        //console.log(`Parent received ${message.data.type} :>> `, message.data);
      }
    }

    function postMessage(message) {
      if (frameRef.current) {
        //console.log(`Parent sending ${message.type} :>> `, message);
        frameRef.current.contentWindow.postMessage(message, origin);
      }
    }

    window.addEventListener("message", async (message) => {
      const { type, correlationId, payload } = message.data;
      if (!type || types[type] === undefined || !correlationId) {
        return;
      }
      if (messages.current.find((id) => id === correlationId)) {
        return;
      }
      messages.current.push(correlationId);
      log(message);
      switch (type) {
        case types.INIT: {
          if (!origin) {
            setOrigin(message.origin);
            postMessage({
              type,
              correlationId,
              payload: {
                origin: window.location.origin,
              },
            });
          }
          break;
        }
        case types.NAVIGATE: {
          navigate(payload.uri);
          break;
        }
        case types.GET_ORDER: {
          const { orderId } = payload;
          const order = await getOrder({ tenantId, token, orderId });
          postMessage({
            type,
            correlationId,
            payload: {
              ...order,
            },
          });
          break;
        }
        case types.GET_ORDER_LOOKUPS: {
          const { marketId } = payload;
          const orderLookups = await getOrderLookups({
            tenantId,
            token,
            marketId,
          });
          postMessage({
            type,
            correlationId,
            payload: {
              ...orderLookups,
            },
          });
          break;
        }
        case types.GET_MARKETS: {
          const markets = await getMarkets({ tenantId, token });
          postMessage({
            type,
            correlationId,
            payload: {
              markets: [...markets.markets],
            },
          });
          break;
        }
        case types.ADVERTISER_SEARCH: {
          const { marketId, advertiserName } = payload;
          const advertisers = await advertiserSearch({
            tenantId,
            token,
            marketId,
            advertiserName,
          });
          postMessage({
            type,
            correlationId,
            payload: {
              advertisers: [...advertisers.result.advertisers],
            },
          });
          break;
        }
        case types.POST_ORDER: {
          const result = await postOrder.mutateAsync({
            tenantId,
            token,
            order: payload,
          });
          const body = await result.json();
          const { orderId } = body.result;
          postMessage({
            type,
            correlationId,
            payload: {
              orderId,
            },
          });
          break;
        }
        default:
        //Ignore
      }

      return () => {
        window.removeEventListener("message", () => {});
      };
    });
  }, [
    origin,
    navigate,
    frameRef,
    getOrder,
    getOrderLookups,
    getMarkets,
    advertiserSearch,
    postOrder,
    tenantId,
    token,
  ]);

  const display = origin ? "block" : "none";

  return (
    <>
      <iframe
        ref={frameRef}
        src={src}
        title="sales-cloud"
        width="100%"
        height="100%"
        style={{ display }}
      />
      {!origin && <div>{src} is loading...</div>}
    </>
  );
}
