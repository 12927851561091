import { Field } from "formik";
import { DropDownListField } from "../utils";
export function Markets({ markets, setMarketId, setFieldValue }) {
  return (
    <Field
      as={DropDownListField}
      name="marketId"
      onChange={(e) => {
        if (e && e.itemData && e.itemData.value) {
          const marketId = parseInt(e.itemData.value);
          setFieldValue("marketId", marketId);
          setMarketId(marketId);
        }
      }}
      values={markets?.map((market) => ({
        value: market.id,
        text: market.name,
      }))}
      text={markets ? markets[0].name : ""}
    />
  );
}
