import { useQueryClient } from "react-query";
import { v4 as uuidv4 } from "uuid";

import { config } from "../components/config";

export function useGetOrder() {
  const queryClient = useQueryClient();
  return async function ({ tenantId, token, orderId }) {
    async function getOrder({ queryKey }) {
      const [, tenantId, token, orderId] = queryKey;
      if (!orderId) {
        return undefined;
      }
      const response = await fetch(
        `${config.server}/orders/${orderId}?skipValidation=true`,
        {
          headers: {
            Authorization: `Bearer ${tenantId}::${token}`,
            Accept: "application/json",
            "x-request-id": uuidv4(),
          },
          method: "GET",
        }
      );
      return await response.json();
    }
    const query = await queryClient.fetchQuery(
      ["order", tenantId, token, orderId],
      getOrder
    );
    return query?.result?.order;
  };
}
