import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";

export function HeaderActionButtons({ order }) {
  return (
    <div
      className="col-2 bg-transparent p-0 align-items-end  d-flex flex-column  m-0"
      style={{ border: "0px", whiteSpace: "nowrap" }}
    >
      <div
        className="text-center pt-0 pb-2 pr-3 pl-0"
        style={{ minWidth: "145px", maxWidth: "145px", height: "39px" }}
      >
        <DropDownButtonComponent cssClass="e-info e-block auto-resize-element-font text-capitalize">
          Actions
        </DropDownButtonComponent>
      </div>
    </div>
  );
}
