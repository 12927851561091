import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { GetToken } from "./GetToken";
import { useAuthenticate } from "../components";

export function LocalApp({ tenantId, token, setTenantId, setToken }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const localTenantId = localStorage.getItem("tenantId") || tenantId;
  const localToken = localStorage.getItem("token") || token;
  const userData = useAuthenticate(localTenantId, localToken);

  useEffect(() => {
    const isAuthenticated = userData?.status === "success";
    if (isAuthenticated) {
      localStorage.setItem("tenantId", localTenantId);
      localStorage.setItem("token", localToken);
      if (token !== localToken) {
        setToken(localToken);
        setTenantId(localTenantId);
      }
      setIsAuthenticated(true);
    } else if (userData) {
      localStorage.removeItem("tenantId");
      localStorage.removeItem("token");
      setTenantId(undefined);
      setToken(undefined);
      setIsAuthenticated(false);
    }
  }, [userData, localTenantId, localToken, setTenantId, setToken, token]);

  return (
    <>
      <h2>Sales Cloud Test Harness</h2>
      {!isAuthenticated && (
        <>
          <GetToken setTenantId={setTenantId} setToken={setToken} />
        </>
      )}
      {isAuthenticated && (
        <>
          <h3>Frame Versions</h3>
          <p>These are the version that is used by Mediascape.</p>
          <ul>
            <li>
              <Link to="/msp/orders/128849024788">
                Sales Cloud Order 128849024788
              </Link>
            </li>
            <li>
              <Link to="/msp/newOrder">New Order</Link>
            </li>
          </ul>
          <h3>Component Versions</h3>
          <p>
            These version aren't wrapped in an iframe. This can be useful for
            debugging, as you can`t see the components in the browser's
            javascript console.
          </p>
          <ul>
            <li>
              <Link
                to={`/local/orders/128849024788?tenant=beasley_test&tenantId=${tenantId}&token=${token}`}
              >
                Sales Cloud Order 128849024788
              </Link>
            </li>
            <li>
              <Link
                to={`/local/newOrder?tenant=beasley_test&tenantId=${tenantId}&token=${token}`}
              >
                New Order
              </Link>
            </li>
          </ul>
          <button
            onClick={() => {
              setIsAuthenticated(false);
              setTenantId(undefined);
              setToken(undefined);
              localStorage.removeItem("tenantId");
              localStorage.removeItem("token");
            }}
          >
            Delete Token
          </button>
        </>
      )}
    </>
  );
}
