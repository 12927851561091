import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";

import { OrderComponent } from "./OrderComponent";
import { useFrameContext } from "../frameContext";

export function Order(props) {
  const [order, setOrder] = useState();
  const [orderLookups, setOrderLookups] = useState();
  const [lastUpdate, setLastUpdate] = useState(DateTime.now());
  const { orderId } = useParams();
  const { getOrder, getOrderLookups, postOrder } = useFrameContext();

  useEffect(() => {
    (async () => {
      const order = await getOrder(orderId);
      setOrder(order);
    })();
  }, [orderId, getOrder, lastUpdate]);

  useEffect(() => {
    (async () => {
      if (order) {
        const marketId = order.traffic_campaign_markets[0].market_id;
        const lookups = await getOrderLookups(marketId);
        setOrderLookups(lookups);
      }
    })();
  }, [order, getOrderLookups, setOrderLookups]);

  async function updateOrder(updatedOrder) {
    const sanitizedOrder = {
      ...updatedOrder,
      advertiser: undefined,
      advertiser_id: updatedOrder.advertiser.id,
    };
    await postOrder(sanitizedOrder);
    setLastUpdate(DateTime.now());
  }

  return (
    <>
      {order && (
        <OrderComponent
          {...props}
          order={order}
          orderLookups={orderLookups}
          updateOrder={updateOrder}
        />
      )}
      {!order && <div>Loading...</div>}
    </>
  );
}
