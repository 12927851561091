import { useQuery } from "react-query";
import { v4 as uuidv4 } from "uuid";

import { config } from "../config";

export function useAuthenticate(tenantId, token) {
  async function validateToken({ queryKey }) {
    const [, tenantId, token] = queryKey;
    if (!tenantId || !token) {
      return undefined;
    }
    const response = await fetch(`${config.server}/auth/validateToken`, {
      headers: {
        Authorization: `Bearer ${tenantId}::${token}`,
        Accept: "application/json",
        "x-request-id": uuidv4(),
      },
      method: "GET",
    });
    return await response.json();
  }
  const query = useQuery(["validateToken", tenantId, token], validateToken);
  return query?.data;
}
