export function AdvertiserSearch({
  advertiserSearch,
  marketId,
  setAdvertisers,
}) {
  return (
    <div>
      <input
        data-cy="advertiser-search"
        className="e-input"
        type="text"
        placeholder="Start typing an advertiser name"
        onChange={async (e) => {
          const data = await advertiserSearch(marketId, e.target.value);
          setAdvertisers(data?.advertisers);
        }}
      />
    </div>
  );
}
