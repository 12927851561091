import { useMutation, useQueryClient } from "react-query";
import { v4 as uuidv4 } from "uuid";

import { config } from "../components/config";

export function usePostOrder() {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    ({ tenantId, token, order }) => {
      return fetch(`${config.server}/orders?skipValidation=true`, {
        headers: {
          Authorization: `Bearer ${tenantId}::${token}`,
          Accept: "application/json",
          "x-request-id": uuidv4(),
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(order),
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("order");
      },
    }
  );

  return mutation;
}
