import { useState, useEffect } from "react";

import { NewOrderComponent } from "./NewOrderComponent";
import {
  orderSkeleton,
  getAdvertiserAccountId,
  getMarketDefaults,
  orderName,
  getDefaultSalespersonId,
} from "./util";
import { useFrameContext } from "../frameContext";
import { useAuthContext } from "../auth";

export function NewOrder(props) {
  const { local, tenant, tenantId, token } = props;
  const [marketId, setMarketId] = useState();
  const [advertiser, setAdvertiser] = useState();
  const [markets, setMarkets] = useState();
  const { getMarkets, navigate, advertiserSearch, postOrder, getOrderLookups } =
    useFrameContext();
  const { user } = useAuthContext();

  useEffect(() => {
    (async () => {
      const markets = await getMarkets();
      setMarkets(markets?.markets?.filter((market) => market.active));
    })();
  }, [setMarkets, getMarkets]);

  async function onSubmit(values) {
    const { advertiserId } = values;
    const advertiserAccountId = getAdvertiserAccountId(advertiser, marketId);
    const { makeGoodPolicyId, competitiveTypeId, revenueTypeId } =
      getMarketDefaults(markets, marketId);
    const name = orderName(advertiser);
    const orderLookups = await getOrderLookups(marketId);
    const salespersonId = getDefaultSalespersonId(user, orderLookups);
    const order = orderSkeleton(
      name,
      marketId,
      advertiserId,
      advertiserAccountId,
      makeGoodPolicyId,
      competitiveTypeId,
      revenueTypeId,
      salespersonId
    );
    const ret = await postOrder(order);
    const { orderId } = ret;
    const uri = local
      ? `/local/orders/${orderId}?tenant=${tenant}&tenantId=${tenantId}&token=${token}`
      : `/msp/orders/${orderId}`;
    navigate(uri);
  }

  return (
    <NewOrderComponent
      {...props}
      markets={markets}
      advertiserSearch={advertiserSearch}
      navigate={navigate}
      setMarketId={setMarketId}
      setAdvertiser={setAdvertiser}
      onSubmit={onSubmit}
    />
  );
}
