import { QueryClient, QueryClientProvider } from "react-query";

import { IncludeCss } from "./css";
import { FrameContext } from "./frameContext";
import { AuthContext } from "./auth";

function getParams(queryString) {
  const urlParams = new URLSearchParams(queryString);
  return {
    tenant: urlParams.get("tenant"),
    tenantId: urlParams.get("tenantId"),
    token: urlParams.get("token"),
  };
}

export function Wrapper({ children, local }) {
  const { tenant, tenantId, token } = getParams(window.location.search); //NOTE: useParams won't work here
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <AuthContext tenant={tenant} tenantId={tenantId} token={token}>
        <FrameContext local={local} tenantId={tenantId} token={token}>
          <IncludeCss>
            <div className="sales-cloud-wrapper">{children}</div>
          </IncludeCss>
        </FrameContext>
      </AuthContext>
    </QueryClientProvider>
  );
}
