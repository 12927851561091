import {
  useState,
  createContext,
  useContext,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";

import {
  useGetMarkets,
  useGetOrder,
  useGetOrderLookups,
  useAdvertiserSearch,
  usePostOrder,
} from "../../hooks";

export const types = {
  INIT: "INIT",
  NAVIGATE: "NAVIGATE",
  GET_ORDER: "GET_ORDER",
  GET_ORDER_LOOKUPS: "GET_ORDER_LOOKUPS",
  GET_MARKETS: "GET_MARKETS",
  ADVERTISER_SEARCH: "ADVERTISER_SEARCH",
  POST_ORDER: "POST_ORDER",
};

const TIMEOUT = 10_000;

const Context = createContext({});

export function useFrameContext() {
  return useContext(Context);
}

export function FrameContext({ children, local, tenantId, token }) {
  if (!local) {
    return <IFrameContext>{children}</IFrameContext>;
  } else {
    return (
      <LocalFrameContext tenantId={tenantId} token={token}>
        {children}
      </LocalFrameContext>
    );
  }
}

function IFrameContext({ children }) {
  const [origin, setOrigin] = useState();
  const messages = useRef([]);

  const postMessage = useCallback(
    (message) => {
      const correlationId = uuidv4();
      if (messages.current.find((id) => id === correlationId)) {
        return;
      }
      messages.current.push(correlationId);
      const msg = { ...message, correlationId };
      console.log(
        `Sending ${msg.type} @ ${DateTime.now().toISOTime({
          includeOffset: false,
        })} :>> `,
        msg
      );
      window.parent.postMessage(msg, origin ? origin : "*");
      return correlationId;
    },
    [origin]
  );

  const log = useCallback((message) => {
    if (message.data.type) {
      console.log(
        `Received ${message.data.type} @ ${DateTime.now().toISOTime({
          includeOffset: false,
        })} :>> `,
        message.data
      );
    }
  }, []);

  const postMessageRPC = useCallback(
    (message) => {
      return new Promise((resolve, reject) => {
        const correlationId = postMessage(message);
        if (correlationId) {
          function handleMessage(msg) {
            if (
              msg.data.type === message.type &&
              msg.data.correlationId === correlationId
            ) {
              log(msg);
              window.removeEventListener("message", handleMessage);
              clearTimeout(timeoutId);
              resolve(msg.data.payload);
            }
          }
          window.addEventListener("message", handleMessage);
          const timeoutId = setTimeout(() => {
            window.removeEventListener("message", handleMessage);
            reject("timeout retrieving data from server");
          }, TIMEOUT);
        }
      });
    },
    [postMessage, log]
  );

  useEffect(() => {
    (async () => {
      if (!origin) {
        const ret = await postMessageRPC({
          type: types.INIT,
          payload: { origin: window.location.toString() },
        });
        if (ret?.origin) {
          setOrigin(ret.origin);
        }
      }
    })();
  }, [origin, postMessageRPC, log]);

  function navigate(uri) {
    postMessage({ type: types.NAVIGATE, payload: { uri } });
  }

  function getOrder(orderId) {
    return postMessageRPC({ type: types.GET_ORDER, payload: { orderId } });
  }

  function getOrderLookups(marketId) {
    return postMessageRPC({
      type: types.GET_ORDER_LOOKUPS,
      payload: { marketId },
    });
  }

  function getMarkets() {
    return postMessageRPC({ type: types.GET_MARKETS });
  }

  function advertiserSearch(marketId, advertiserName) {
    return postMessageRPC({
      type: types.ADVERTISER_SEARCH,
      payload: { marketId, advertiserName },
    });
  }

  function postOrder(order) {
    return postMessageRPC({ type: types.POST_ORDER, payload: { ...order } });
  }

  const value = {
    navigate,
    getOrder,
    getOrderLookups,
    getMarkets,
    advertiserSearch,
    postOrder,
  };

  return (
    <>
      {origin && <Context.Provider value={value}>{children}</Context.Provider>}
    </>
  );
}

function LocalFrameContext({ children, tenantId, token }) {
  const navigate = useNavigate();
  const getOrderFn = useGetOrder();
  const getOrderLookupsFn = useGetOrderLookups();
  const getMarketsFn = useGetMarkets();
  const advertiserSearchFn = useAdvertiserSearch();
  const postOrderFn = usePostOrder();

  async function getOrder(orderId) {
    return await getOrderFn({ tenantId, token, orderId });
  }

  async function getOrderLookups(marketId) {
    return await getOrderLookupsFn({
      tenantId,
      token,
      marketId,
    });
  }

  async function getMarkets() {
    return await getMarketsFn({ tenantId, token });
  }

  async function advertiserSearch(marketId, advertiserName) {
    const advertisers = await advertiserSearchFn({
      tenantId,
      token,
      marketId,
      advertiserName,
    });
    return {
      advertisers: [...advertisers.result.advertisers],
    };
  }

  async function postOrder(order) {
    const result = await postOrderFn.mutateAsync({
      tenantId,
      token,
      order,
    });
    const body = await result.json();
    return body.result;
  }

  const value = {
    navigate,
    getOrder,
    getOrderLookups,
    getMarkets,
    advertiserSearch,
    postOrder,
  };

  return (
    <>
      <Context.Provider value={value}>{children}</Context.Provider>
    </>
  );
}
