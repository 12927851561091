import { useState } from "react";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";

export function DropDownListField({
  onChange,
  onBlur,
  name,
  value,
  values,
  text,
}) {
  const [event, setEvent] = useState();
  return (
    <DropDownListComponent
      name={name}
      dataSource={values}
      value={value}
      change={(e) => {
        setEvent(e);
        onChange(e);
      }}
      blur={() => {
        if (event && event.e && event.e.target) {
          onBlur({ ...event, target: event.e.target });
        }
      }}
      text={text}
    />
  );
}
