import { useState, useEffect, useRef } from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";

import { SetDisabled } from "./SetDisabled";
import { Markets } from "./Markets";
import { AdvertiserSearch } from "./AdvertiserSearch";
import { AdvertiserSearchResults } from "./AdvertiserSearchResults";

export function NewOrderForm({
  markets,
  advertiserSearch,
  submit,
  setSubmit,
  disabled,
  setDisabled,
  setAdvertiser,
  setMarketId,
  onSubmit,
}) {
  const [advertisers, setAdvertisers] = useState([]);
  const formRef = useRef(null);

  useEffect(() => {
    function handleSubmit() {
      if (formRef.current) {
        formRef.current.handleSubmit();
      }
    }
    if (submit) {
      handleSubmit();
      setSubmit(false);
    }
  }, [formRef, submit, setSubmit]);

  const validationSchema = yup.object().shape({
    marketId: yup.number().required().positive().integer(),
    advertiserId: yup.number().required().positive().integer(),
  });

  return (
    <Formik
      initialValues={{
        marketId: markets ? markets[0].id : 0,
        advertiserId: 0,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      innerRef={formRef}
    >
      {({ values, setFieldValue, isValid }) => (
        <Form>
          <SetDisabled
            isValid={isValid}
            disabled={disabled}
            setDisabled={setDisabled}
          />
          <Markets
            markets={markets}
            setFieldValue={setFieldValue}
            setMarketId={setMarketId}
          />
          <AdvertiserSearch
            advertiserSearch={advertiserSearch}
            marketId={values.marketId}
            setAdvertisers={setAdvertisers}
          />
          <AdvertiserSearchResults
            advertisers={advertisers}
            setAdvertiser={setAdvertiser}
            setFieldValue={setFieldValue}
          />
        </Form>
      )}
    </Formik>
  );
}
