import { useContext, createContext } from "react";

import { useAuthenticate } from "./useAuthenticate";

const Context = createContext({});

export function useAuthContext() {
  return useContext(Context);
}

export function AuthContext({ tenant, tenantId, token, children }) {
  const userData = useAuthenticate(tenantId, token);
  const isAuthenticated = userData?.status === "success";
  const user = userData?.result?.data;

  const value = {
    tenant,
    tenantId,
    token,
    isAuthenticated,
    user,
  };

  return (
    <>
      {isAuthenticated && (
        <Context.Provider value={value}>{children}</Context.Provider>
      )}
    </>
  );
}
