import { useState } from "react";

export function OrderName({ order, updateOrder }) {
  const [name, setName] = useState(order.name);

  return (
    <div
      className="col-4 p-0 m-0 "
      style={{
        display: "flex",
        alignItems: "center",
        border: "0px",
        paddingLeft: "4px!important",
      }}
    >
      <span className="e-input-group e-control-wrapper e-control-container m-0 display-Commenticon comment-title-font customer-textbox">
        <input
          data-cy="order-name"
          className="e-control e-textbox e-lib e-input"
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={async () => await updateOrder({ ...order, name })}
        />
      </span>
      <i className="e-icons EditItem display-iconComment"></i>
    </div>
  );
}
