import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { LocalApp } from "./LocalApp";
import styles from "./AppRouter.module.css";
import { MspIframe } from "./MspIframe";
import { SalesCloudOrder, NewOrder } from "../components";

export function AppRouter() {
  return (
    <div className={styles.container}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </div>
  );
}

function AppRoutes() {
  const [token, setToken] = useState();
  const [tenantId, setTenantId] = useState();

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <LocalApp
              tenantId={tenantId}
              setTenantId={setTenantId}
              token={token}
              setToken={setToken}
            />
          }
        />
        <Route
          path="orders/:orderId"
          element={
            <SalesCloudOrder
              tenantId={tenantId}
              tenant="beasley_test"
              token={token}
            />
          }
        />
        <Route
          path="newOrder"
          element={
            <NewOrder tenantId={tenantId} tenant="beasley_test" token={token} />
          }
        />
        <Route path="msp">
          <Route
            path="orders/:orderId"
            element={
              <MspIframe
                tenant="beasley_test"
                tenantId={tenantId}
                token={token}
                uri={"/orders/:orderId"}
              />
            }
          />
          <Route
            path="newOrder"
            element={
              <MspIframe
                tenant="beasley_test"
                tenantId={tenantId}
                token={token}
                uri={"/newOrder"}
              />
            }
          />
        </Route>
        <Route path="local">
          <Route
            path="orders/:orderId"
            element={
              <SalesCloudOrder
                tenantId={tenantId}
                tenant="beasley_test"
                token={token}
                local={true}
              />
            }
          />
          <Route
            path="newOrder"
            element={
              <NewOrder
                tenantId={tenantId}
                tenant="beasley_test"
                token={token}
                local={true}
              />
            }
          />
        </Route>
        <Route path="*" element={<p>Not Found</p>} />
      </Routes>
    </>
  );
}
